import { Section, Accordion, AccordionSection, Paragraph } from "@polestar/component-warehouse-react";
import React from "react";

export function Accoding() {
    // const disabled = boolean("Disabled", false);

    return (
        <Section>
            <Accordion
                // onToggle={action("onToggle")}
                // disabled={disabled}
                // border={boolean("Show border divider when expanded", true)}
            >
                {Array(1)
                    .fill(null)
                    .map((item, index) => (
                        <AccordionSection title={"HMI"}>
                            <Accordion
                                // onToggle={action("onToggle")}
                                // border={boolean("Show border divider when expanded", true)}
                            >
                                <AccordionSection title={"Document"}>
                                    <Paragraph>Instructions
                                        
                                        
Use Android Studio 4.2 or newer. Currently available at Android Studio

Download and Install the Polestar 2 emulator
In Android Studio, select Tools > SDK Manager.

Click the SDK Update Sites tab.

Click Add and enter the following Name and URL, then click OK:
Name: Polestar 2 System Image
URL: https://developer.polestar.com/sdk/polestar2-sys-img.xml

Switch to the SDK Platforms tab and enable the Show Package Details checkbox.

Enable Polestar 2 under Android 9.0. Click OK.

Create an AVD using the Polestar 2 emulator
In Android Studio, select Tools > AVD Manager.

Click Create Virtual Device and select Automotive from the menu on the left.

Choose Polestar 2 with the Play Store icon and click Next (if you do not see the Play Store icon then restart Android Studio! It will appear when you open AVD manager again).

Click the x86 Images tab and download the Polestar 2 emulator. Select it in the list, click Next, then Finish.</Paragraph>
                                </AccordionSection>
                                <AccordionSection
                                    title={"Image"}
                                    subtitle={"image download link"}
                                >
                                    <Paragraph>blabla</Paragraph>
                                </AccordionSection>
                            </Accordion>
                        </AccordionSection>
                    ))}
            </Accordion>
        </Section>
    );
};