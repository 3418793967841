import { Center, Colors, Header, Icon, Icons, NavigationDrawer, Orientations, Placeholder, PolestarApp, PrimaryButton, Row, SafeArea, Scaffold, Section, Spacer, Spacings, Spinner, SpinnerTypes, StepNavigation, TextButton } from '@polestar/component-warehouse-react';
import React, { useCallback, useState } from 'react';
import './App.css';
import { Accoding } from './components/according';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  const handleButtonPress = useCallback(() => {
    setIsLoading(!isLoading);
  }, [isLoading]);

  return (
    <PolestarApp>
      <Scaffold
        header={
          <Header
            actions={
              <Row>
                Hackathon2021
              </Row>
            }
            menuButton={false}
            logoProps={{
              as: "a",
              href: "https://www.polestar.com",
              target: "blank",
              rel: "noopener"
            }}
          />
        }
      >
        <SafeArea>
          <Section>
            <Placeholder width="100%" height="15vh" label="Welcome to Polestar hackathon 2021"/>
            <Spacer spacing={Spacings.xLarge} />
          </Section>
        </SafeArea>
      </Scaffold>
      <Accoding></Accoding>
    </PolestarApp>
  );
}

export default App;
